<template>
  <div class="dx-swatch-additional side-navigation-menu" @click="forwardClick">
    <slot />
    <div class="menu-container">    
      <dx-tree-view ref="treeViewRef" :items="navigation" key-expr="'id'" selection-mode="single" class="xx"
        :focus-state-enabled="false" expand-event="click" @item-click="handleItemClick" width="100%" 
        item-template="product-template">
        <template #product-template="product">
            
            <img v-if="product.data.text==='Home'" style="width:30px; margin:0 15px" src="@/assets/yj/form_icon_00.png">
            <img v-if="product.data.text==='CYCLETIME'" style="width:30px; margin:0 15px" src="@/assets/yj/form_icon_01.png">
            <img v-if="product.data.text==='WIP'" style="width:30px; margin:0 15px" src="@/assets/yj/form_icon_02.png">
            <img v-if="product.data.text==='YIELD'" style="width:30px; margin:0 15px" src="@/assets/yj/form_icon_03.png">
            <img v-if="product.data.text==='INOUT'" style="width:30px; margin:0 15px" src="@/assets/yj/form_icon_04.png">
            <img v-if="product.data.text==='RECEIVE'" style="width:30px; margin:0 15px" src="@/assets/yj/form_icon_05.png">
            <img v-if="product.data.text==='SHIP'" style="width:30px; margin:0 15px" src="@/assets/yj/form_icon_06.png">
            {{product.data.text }}
        </template>

      </dx-tree-view>
    </div>
  </div>
</template>

<script>
  import DxTreeView from 'devextreme-vue/tree-view';
  import { sizes } from '../utils/media-query';
  // import navigation from '../app-navigation';
  import { onMounted, ref, watch, inject, reactive, /*computed*/ } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from "axios";

  import { getKey, decryptData } from "@/components/CryptoComponent.js";
  export default {
    props: {
      compactMode: Boolean
    },

    setup(props, context) {
      const router = useRouter();
      //inject =>子組件中取得父組件提供的值
      const clickedMenu = inject('clickedMenu');
      //console.log('clickedMenu', clickedMenu)
      const isLargeScreen = sizes()['screen-large'];
      const navigation = ref([]);
      const treeViewRef = ref(null);

      function forwardClick(...args) {
        context.emit("click", args);
      }

      function handleItemClick(e) {
        if (!e.itemData.path || props.compactMode) {
          return;
        }
        router.push(e.itemData.path);

        clickedMenu.value = { text: e.itemData.text, value: e.itemData.value };


        const pointerEvent = e.event;
        pointerEvent.stopPropagation();
      }

      function updateSelection() {
        if (!treeViewRef.value || !treeViewRef.value.instance) {
          return;
        }
        treeViewRef.value.instance.selectItem(router.path);
        treeViewRef.value.instance.expandItem(router.path);
      }

      onMounted(() => {
        if (Array.isArray(navigation.value)) {
          fetchAndTransformData();
        }
        updateSelection();
        if (props.compactMode) {
          treeViewRef.value.instance.collapseAll();
        }
      });
      const transformedData = reactive([]);

      const fetchAndTransformData = async () => {
        let get_key = null;
        // 在組件掛載時生成密鑰
        get_key = await getKey();
        let encryptedData = sessionStorage.getItem('encryptedMatchedAccount');
        const [ciphertext, iv] = encryptedData.split(':');
        const decryptedText = await decryptData(ciphertext, get_key, iv);

        console.log('fetchAndTransformData', navigation.value)
        let groupedItems = [];
        const Me_Companyid = JSON.parse(decryptedText).companyid;
        //根據權限篩選能看到的menu
        await axios.post(
          `${window.BaseUrl}/api/Csweb2/AUTHORIZATION`,
          { "companyid": Me_Companyid }
        ).then(response => {
          const apiData = response.data.payload.sameMailAccountList;
          // console.log('apiData', apiData);
          //針對reportid排序
          apiData.sort((a, b) => {
            // console.log(a.reportid, b.reportid);
            if (a.reportid > b.reportid) return 1;
            if (a.reportid < b.reportid) return -1;
            return 0;
          })
          // 使用 reduce 處理數組
          groupedItems = Object.values(apiData.reduce((acc, item, index) => {

            const modifiedItem = {
              id: index + 1,
              //取得顯示的名稱，拿掉SimplifyStep或NormalStep字眼，空白取代底線
              text: item.reportid.replace(/(SimplifyStep|NormalStep)/, '').replace(/[_\s]+/g, ' ').trim(),
              value: `${item.processtype}_${item.reporttype}`,
              path: "/csweb_temp",
            }
            if (!acc[item.reporttype]) {
              // 有一樣的reporttype
              acc[item.reporttype] = {
                icon: "folder",
                text: item.reporttype,
                items: [modifiedItem]
              };
            } else {// 否則，將 item 新增到現有的分組
              const existingItems = acc[item.reporttype].items;
              //但不新增重複的(因拿掉SimplifyStep或NormalStep字眼，空白取代底線，所以會有重複名稱)
              const itemExists = existingItems.some(existingItem => existingItem.value === modifiedItem.value);
              if (!itemExists) { // 如果不存在，則新增 modifiedItem existingItems.push(modifiedItem); }
                acc[item.reporttype].items.push(modifiedItem);
              }
            }
            return acc;
          }, []));
          navigation.value = groupedItems;

          navigation.value.unshift({ text: "Home", path: "/Home", icon: "home" });
        })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        await axios.post(`${window.BaseUrl}/api/cProductionInfo/cProInfo`, { companyid: Me_Companyid })
          .then((response) => {
            if (Array.isArray(response.data)) {
              navigation.value.push({ text: "Customize Report", path: "/ProductionInfo", icon: "folder" });
            }
          }).catch(error => {
            console.error('Error fetching data:', error);
          });
        // 輸出結果

        navigation.value.map((item) => {
          if (item.path && !(/^\//.test(item.path))) {
            item.path = `/${item.path}`;
          }
          return { ...item, expanded: isLargeScreen }
        });
      };

      watch(
        () => router.path,
        () => {
          updateSelection();
        }
      );

      watch(
        () => props.compactMode,
        () => {
          if (props.compactMode) {
            treeViewRef.value.instance.collapseAll();
          } else {
            updateSelection();
          }
        }
      );

      return {
        treeViewRef,
        transformedData,
        navigation,
        forwardClick,
        handleItemClick,
        updateSelection,

      };
    },
    components: {
      DxTreeView
    }
  };
</script>

<style lang="scss">
  @import "../dx-styles.scss";
  @import "../themes/generated/variables.additional.scss";

  .dx-theme-material-typography .dx-swatch-additional,
  .dx-theme-material-typography.dx-swatch-additional {
    // background: $base-all-background;
    background-color: #214c7f;
  }

  .side-navigation-menu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: 250px !important;

    .menu-container {
      min-height: 100%;
      display: flex;
      flex: 1;

      .dx-treeview {
        /* Long text positioning*/
        white-space: nowrap;


        /* Icon width customization*/
        .dx-treeview-item {
          padding-left: 0;
          padding-right: 0;
          color: $base-bg;

          .dx-icon {
            width: $side-panel-min-width !important;
            margin: 0 !important;

          }
        }


        /*Arrow customization*/
        .dx-treeview-node {
          padding: 0 0 !important;
        }

        .dx-treeview-toggle-item-visibility {
          right: 10px;
          left: auto;
          color: $base-bg ;
        }

        .dx-rtl .dx-treeview-toggle-item-visibility {
          left: 10px;
          right: auto;

        }


        /*Item levels customization*/
        .dx-treeview-node {

          &[aria-level="1"] {
            font-weight: bold;
            font-size: 15px;
            /* border-bottom: 1px solid $base-border-color; */

          }

          &[aria-level="2"] .dx-treeview-item-content {
            // font-weight: normal;
            font-weight: 500;
            font-size: 14px;
            padding: 0 $side-panel-min-width;
          }
        }

      }

      /* Selected & Focuced items customization*/
      .dx-treeview {
        .dx-treeview-node-container {
          .dx-treeview-node {
            &.dx-state-selected:not(.dx-state-focused)>.dx-treeview-item {
              background: transparent;
            }

            &.dx-state-selected>.dx-treeview-item * {
              color: $base-accent;
              font-weight: 700;
            }

            &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
              background-color: #385e8c;
              /* color: $base-all-background; */
              // color: black;
            }


          }
        }
      }

      .dx-theme-generic .dx-treeview {
        .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused>.dx-treeview-item * {
          color: inherit;
        }
      }

      /**/
    }
  }
</style>