<template>
  <!-- <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
     <div class="dx-card content">
      <div class="header">
        <div class="title">7987</div>
        <div class="description">546465</div>
      </div>
      <slot />
    </div>
  </dx-scroll-view> -->
<!-- <dx-scroll-view height="100%" width="100%" > -->
  <div class="with-footer single-card"  height="100%" width="100%" id="lazyloaded" >
   <div >
    <slot />
   </div>
  </div>
   <!-- </dx-scroll-view> -->
</template>

<script>
// import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    // DxScrollView
  },
  props: {
    title: String,
    description: String
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
 #lazyloaded{
    padding: 0px !important;
     background:$base-bg-dark;
     
    /* background-image: url(https://3711cdn.r.worldssl.net/wp-content/themes/ase-3711/theme/img/pic/pic-home-csr.jpg); */
     background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 20rem;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-bg-dark;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        /* color: rgba($base-text-color, alpha($base-text-color) * 0.7); */
          color: $base-bg-dark;
        line-height: 18px;
      }
    }
  }

}
</style>
