<template>
  <!-- <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
     <div class="dx-card content">
      <div class="header">
        <div class="title">7987</div>
        <div class="description">546465</div>
      </div>
      <slot />
    </div>
  </dx-scroll-view> -->
  <!-- <dx-scroll-view height="100%" width="100%" > -->
  <div class="with-footer single-card" height="100%" width="100%" id="lazy_loaded" style="color:#fff">
    <div>
      <slot />
    </div>
  </div>
  <!-- </dx-scroll-view> -->
</template>

<script>
  // import DxScrollView from "devextreme-vue/scroll-view";

  export default {
    components: {
      // DxScrollView
    },
    props: {
      title: String,
      description: String
    }
  };
</script>

<style lang="scss">
  @import "../themes/generated/variables.base.scss";

  #lazy_loaded {
    background-color: #292626;
    /* background-image: url(../assets/back/圖片10.png); */
    background-repeat: no-repeat;
    background-size: cover;
    /* background: $base-bg-dark; */
    /* background-image: url(https://3711cdn.r.worldssl.net/wp-content/themes/ase-3711/theme/img/pic/pic-home-csr.jpg); */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/yj/login_bg_02.jpg);
    background-position: center;
  }
</style>