

const masterKey = 'my-very-secure-master-key'; // 這是主密鑰，在實際應用上應妥善保護。
// 在生產環境中，建議將主金鑰儲存在更安全的地方，例如環境變數或安全的設定檔。
/**產生一個新的 AES-GCM 加密金鑰，並將其加密後儲存在 sessionStorage 中。
@returns {CryptoKey} 產生的加密金鑰**/

async function generateKey() {
      // 產生一個 AES-GCM 加密金鑰
      const key = await window.crypto.subtle.generateKey(
            {
                  name: 'AES-GCM', // 使用 AES-GCM 演算法進行加密
                  length: 256, // 金鑰長度為 256 位元
            },
            true, // 是否可以匯出金鑰
            ['encrypt', 'decrypt'] // 金鑰的用途，既可以用於加密也可以用於解密
      );
      // 匯出產生的金鑰
      const exportedKey = await window.crypto.subtle.exportKey('jwk', key);
      // 使用主金鑰對匯出的金鑰進行加密
      const encryptedExportedKey = await encryptWithMasterKey(JSON.stringify(exportedKey), masterKey);
      // 將加密後的金鑰儲存在 sessionStorage 中
      sessionStorage.setItem('cryptoKey', encryptedExportedKey);
      return key;
}
/**
從 sessionStorage 取得並解密 AES-GCM 加密金鑰。
如果密鑰不存在，則產生一個新的密鑰。
@returns {CryptoKey} 加密金鑰
*/
async function getKey() {
      if (!window.crypto || !window.crypto.subtle) {
            throw new Error("Web Crypto API is not available in this environment.");
      }
      // 從 sessionStorage 取得儲存的加密金鑰
      const storedKey = sessionStorage.getItem('cryptoKey');
      if (storedKey) {
            // 使用主金鑰解密儲存的金鑰
            const decryptedKey = await decryptWithMasterKey(storedKey, masterKey);
            // 導入解密後的金鑰
            const importedKey = await window.crypto.subtle.importKey(
                  'jwk',// 金鑰的格式為 JSON Web Key (JWK)
                  JSON.parse(decryptedKey),
                  {
                        name: 'AES-GCM',// 使用 AES-GCM 演算法
                  },
                  true,// 是否可以匯出金鑰
                  ['encrypt', 'decrypt']// 金鑰的用途，既可以用於加密也可以用於解密
            );
            return importedKey;
      }
      // 如果 sessionStorage 中沒有儲存金鑰，則產生一個新的金鑰
      return await generateKey();
}

/**
使用主密鑰對資料進行加密。
@param {string} data 要加密的數據
@param {string} masterKey 用於加密的主金鑰
@returns {string} 加密後的數據，格式為 iv:ciphertext
*/
async function encryptWithMasterKey(data, masterKey) {
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(data);

      // 產生一個隨機的初始化向量 (IV)
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      // 使用主金鑰衍生出一個用於加密的金鑰
      const keyMaterial = await window.crypto.subtle.importKey(
            'raw',// 原始格式的金鑰材料
            encoder.encode(masterKey),
            'PBKDF2', // 使用 PBKDF2 演算法派生金鑰
            false, // 是否可以匯出金鑰材料
            ['deriveKey'] // 金鑰材質的用途是派生金鑰
      );
      const key = await window.crypto.subtle.deriveKey(
            {
                  name: 'PBKDF2',// 使用 PBKDF2 演算法
                  salt: iv, // 使用產生的 IV 作為鹽值
                  iterations: 100000, // 迭代次數，越高越安全，但計算時間也越長
                  hash: 'SHA-256', // 使用 SHA-256 雜湊演算法
            },
            keyMaterial,
            { name: 'AES-GCM', length: 256 },// 產生 AES-GCM 金鑰，長度為 256 位元
            false, // 是否可以匯出產生的金鑰
            ['encrypt'] // 產生的金鑰的用途是加密
      );
      // 使用派生的金鑰和 IV 對資料進行加密
      const ciphertext = await window.crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },
            key,
            encodedData
      );
      // 傳回格式為 iv:ciphertext 的加密數據
      return `${btoa(String.fromCharCode(...iv))}:${btoa(String.fromCharCode(...new Uint8Array(ciphertext)))}`;
}

/**
使用主密鑰對加密資料進行解密。
@param {string} encryptedData 要解密的數據，格式為 iv:ciphertext
@param {string} masterKey 用於解密的主金鑰
@returns {string} 解密後的數據
*/
async function decryptWithMasterKey(encryptedData, masterKey) {
      // 使用主金鑰派生一個用於解密的金鑰
      const [iv, ciphertext] = encryptedData.split(':').map(part => new Uint8Array(atob(part).split('').map(char => char.charCodeAt(0))));
      const keyMaterial = await window.crypto.subtle.importKey(
            'raw', // 原始格式的金鑰材料
            new TextEncoder().encode(masterKey),
            'PBKDF2', // 使用 PBKDF2 演算法派生金鑰
            false, // 是否可以匯出金鑰材料
            ['deriveKey'] // 金鑰材質的用途是派生金鑰
      );
      const key = await window.crypto.subtle.deriveKey(
            {
                  name: 'PBKDF2', // 使用 PBKDF2 演算法
                  salt: iv, // 使用解密資料中的 IV 作為鹽值
                  iterations: 100000, // 迭代次數
                  hash: 'SHA-256', // 使用 SHA-256 雜湊演算法
            },
            keyMaterial,
            { name: 'AES-GCM', length: 256 }, // 產生 AES-GCM 金鑰，長度為 256 位元
            false, // 是否可以匯出產生的金鑰
            ['decrypt'] // 產生的金鑰的用途是解密
      );
      // 使用派生的金鑰和 IV 對資料進行解密
      const decryptedData = await window.crypto.subtle.decrypt(
            { name: 'AES-GCM', iv: iv },
            key,
            ciphertext
      );
      return new TextDecoder().decode(decryptedData);
}
/**
使用 AES-GCM 加密資料。
@param {string} data 要加密的數據
@param {CryptoKey} key 用於加密的 AES-GCM 金鑰
@returns {Object} 包含密文和 IV 的對象
*/
async function encryptData(data, key) {
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(data);
      // 產生一個隨機的初始化向量 (IV)
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      // 使用提供的金鑰和 IV 對資料進行加密
      const ciphertext = await window.crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },// 使用 AES-GCM 演算法與產生的 IV
            key,
            encodedData
      );
      // 返回密文和 IV
      return {
            ciphertext: btoa(String.fromCharCode(...new Uint8Array(ciphertext))),
            iv: btoa(String.fromCharCode(...iv)),
      };
}
/**
使用 AES-GCM 解密資料。
@param {string} ciphertext 要解密的密文
@param {CryptoKey} key 用於解密的 AES-GCM 金鑰
@param {string} iv 初始化向量 (IV)
@returns {string} 解密後的數據
*/
async function decryptData(ciphertext, key, iv) {
      const decoder = new TextDecoder();
      const encodedCiphertext = new Uint8Array(atob(ciphertext).split('').map(char => char.charCodeAt(0)));
      const encodedIv = new Uint8Array(atob(iv).split('').map(char => char.charCodeAt(0)));
      // 使用提供的金鑰和 IV 對資料進行解密
      const decryptedData = await window.crypto.subtle.decrypt(
            { name: 'AES-GCM', iv: encodedIv },// 使用 AES-GCM 演算法和提供的 IV
            key,
            encodedCiphertext
      );
      return decoder.decode(decryptedData);
}
export { generateKey, getKey, encryptData, decryptData };


/**
參數和選項解釋：
'raw'：表示輸入的金鑰材料是原始的二進位資料。
new TextEncoder().encode(masterKey)：將主金鑰編碼為 Uint8Array。
'PBKDF2'：表示我們使用 PBKDF2 演算法來衍生金鑰。 PBKDF2(Password - Based Key Derivation Function 2) 是一種金鑰衍生函數，常用於從密碼產生強加密金鑰。
false：表示產生的金鑰材料不能被導出。這有助於保護金鑰的安全性，防止金鑰被意外或惡意匯出和洩漏。
['deriveKey']：表示金鑰材料的用途是派生金鑰。產生的金鑰只能用於派生其他金鑰，而不能直接用於加密或解密作業。
整體流程：
導入原始密鑰材料：首先，我們使用 importKey 方法將主密鑰匯入為原始密鑰材料(keyMaterial)。因為我們設定了 false，所以產生的金鑰材料不能被匯出。
派生金鑰：然後，使用 deriveKey 方法透過 PBKDF2 演算法從導入的金鑰材料(keyMaterial) 派生出一個新的 AES - GCM 加密金鑰。此過程使用了 iv 作為鹽值。
將 false 設為 true 可能會導緻密鑰材料的匯出，從而增加密鑰洩漏的風險。在大多數情況下，我們希望金鑰材料保持不可匯出，以確保金鑰的安全性。

如果需要跨元件傳遞和使用加密金鑰，可以考慮使用安全的方式儲存和傳遞金鑰，如在會話開始時產生金鑰，並將其安全地儲存在記憶體中或使用其他安全機制保護金鑰。可以透過加密金鑰儲存在 sessionStorage 中，並在元件之間傳遞使用，以確保金鑰的安全性和有效性。**/