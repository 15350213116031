<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">

      <dx-item :visible="menuToggleEnabled" location="before" css-class="menu-button">
        <template #default>
          <dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
        </template>
      </dx-item>

      <dx-item v-if="title" location="before" css-class="header-title dx-toolbar-label">
        <template #default>
          <div>
            <a><!--/*href="#/home"*/-->
              <img src="../assets/ASE_LOGOTYPE_Horizontal-CH.png" alt="" style="
              min-width: 2rem;max-height:2rem;" />
            </a>

          </div>
        </template>
      </dx-item>

      <!-- <dx-Item
        location="before">
          <template #default>
              <DxTextBox 
                v-model="form"
                @enter-key="showForm()"
              />
          </template>
      </dx-Item> -->

      <dx-item location="after">
        <template #default>
          <div>
            <dx-button class="user-button authorization" width="100%" height="100%" styling-mode="text">
              <a href="#/cManagement">
                <!-- <a href="#/HOME"> -->
                <div class="user-info">
                  <!-- <div class="image-container"> -->
                  <div class="fa-2x top-info">
                    <i class="fas fa-cog fa-spin"></i>
                  </div>
                  <!-- </div> -->
                  <div class="user-name">Management</div>
                </div>

              </a>
            </dx-button>
          </div>
        </template>
      </dx-item>

      <dx-item location="after">
        <template #default>
          <div>
            <dx-button class="user-button authorization" width="100%" height="100%" styling-mode="text">
              <a href="#/cProduction-Info">
                <!-- <a href="#/HOME"> -->
                <div class="user-info">
                  <div>
                    <!-- <div class="image-container"> -->
                    <!-- <div class="user-image" /> -->
                    <span class="fa-layers fa-fw top-info">
                      <i class="fas fa-list-alt"></i>
                      <!-- <span class="fa-layers-counter" style="background:Tomato">1,419</span> -->
                    </span>
                  </div>

                  <!-- <div class="user-name">{{user.email}}</div> -->
                  <div class="user-name">Production Info</div>
                </div>

              </a>
            </dx-button>
          </div>
        </template>
      </dx-item>


      <dx-item location="after">
        <template #default>
          <div>
            <dx-button class="user-button authorization" width="100%" height="100%" styling-mode="text">
              <a href="#/subscribe-report">
                <div class="user-info">
                  <div>
                    <!-- <div class="image-container"> -->
                    <!-- <div class="user-image" /> -->
                    <span class="fa-layers fa-fw top-info">
                      <i class="fas fa-bell"></i>
                      <!-- <span class="fa-layers-counter" style="background:Tomato">1,419</span> -->
                    </span>
                  </div>

                  <!-- <div class="user-name">{{user.email}}</div> -->
                  <div class="user-name">Subscribe</div>
                </div>

              </a>
            </dx-button>
          </div>
        </template>
      </dx-item>


      <dx-item location="after">
        <template #default>
          <div>
            <dx-button class="user-button authorization" width="100%" height="100%" styling-mode="text">
              <a href="#/ContactUs">
                <!-- <a href="#/HOME"> -->
                <div class="user-info">
                  <div>
                    <!-- <div class="image-container"> -->
                    <!-- <div class="user-image" /> -->
                    <span class="fa-layers fa-fw top-info">
                      <i class="fas fa-envelope"></i>
                      <!-- <span class="fa-layers-counter" style="background:Tomato">1,419</span> -->
                    </span>
                  </div>

                  <!-- <div class="user-name">{{user.email}}</div> -->
                  <div class="user-name">Contact Us</div>
                </div>

              </a>
            </dx-button>
          </div>
        </template>
      </dx-item>





      <dx-item location="after" locate-in-menu="auto">
        <template #default>
          <div class="user-name">
            <DxMenu :data-source="products" show-first-submenu-mode="onHover" orientation="horizontal"
              hide-submenu-on-mouse-leave="true" display-expr="name" @item-click="handleItemClick"
              wrapper-attr="{class: user-name }" />
          </div>
        </template>
      </dx-item>

      <dx-item location="after">
        <template #default>
          <div>
            <dx-button class="user-button authorization" width="100%" height="100%" styling-mode="text">
              <a @click="onLogoutClick()">
                <div class="user-info">
                  <div>
                    <span class="fa-layers fa-fw top-info">
                      <i class="fas fa-sign-out-alt"></i>
                    </span>
                  </div>

                  <div class="user-name">Logout</div>

                </div>

              </a>
            </dx-button>

          </div>
        </template>
      </dx-item>

    </dx-toolbar>
    <DxPopup titleTemplate="title" width="325" height="auto" :visible="ConChangedPop">
      <template #title>
        <div style="display: flex;"><i class="fas fa-user-circle"
            style="font-size: 26px; margin-top: 15px; margin-right: 5px; margin-left: 45px;"></i>
          <p style="font-size: 22px; margin-top: 15px;"><strong>Confirm Changed</strong></p>
        </div>
      </template>
      <p style="font-size: 15px; color: red; text-align: center ; margin-bottom: 30px;">Account change to
        "{{SelChaAcc}}"</p>
      <div class="popup-footer">
        <DxButton text="Confirm" @click="ConChange(true)" styling-mode="text" style="color:#2d6da3;font-size: 15px " />
        <DxButton text="Cancel" @click="ConChange(false)" styling-mode="text"
          style="color:#2d6da3;margin-right: 40px;font-size: 15px" />
      </div>
    </DxPopup>

  </header>
</template>

<script>
  import { DxPopup } from "devextreme-vue";
  import DxButton from "devextreme-vue/button";
  import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
  import auth from "../auth";
  import { ref } from 'vue';
  // import DxTextBox from "devextreme-vue/text-box";
  // import UserPanel from "./user-panel";
  import notify from 'devextreme/ui/notify';
  import rptitem from "../data/RPTITEM.json";
  // import { useRouter } from 'vue-router';
  import { getKey, decryptData, encryptData } from "@/components/CryptoComponent.js";

  import DxMenu from 'devextreme-vue/menu';
  // import DxList from "devextreme-vue/list";
  // import /*DxContextMenu,*/ { DxPosition } from "devextreme-vue/context-menu";
  export default {
    props: {
      menuToggleEnabled: Boolean,
      title: String,
      toggleMenuFunc: Function,
      logOutFunc: Function
    },
    data() {
      return {
        ConChangedPop: false,
        SelChaAcc: '',
        COMA: [],
        company: '',
        selectedLanguage: 'TW',
        products: [{
          id: '1',
          name: 'User',
          icon: 'fas fa-users',
          items: [{
            id: '1_1',
            name: ""/*"Welcome\n \n " + this.company + "\n to log in to CSWEB"*/,
            icon: 'user',
          }, {
            id: '1_2',
            name: "Switch account",
            //path: "/Account",
            icon: 'refresh',
            items: [],
          }],
        },],

      }
    },
    async created() {
      let get_key = null;
      get_key = await getKey();
      let existingData = JSON.parse(sessionStorage.getItem('LoginUserinfo')) || [];
      let [ciphertext, iv] = existingData.split(':');
      //console.log('33', [ciphertext, iv])
      let decryptedText = await decryptData(ciphertext, get_key, iv);
      this.COMA = JSON.parse(decryptedText);
      if (this.COMA.info) {
        this.COMA = this.COMA.info
      }
      this.products[0].items[1].items = await this.generateItems();//this.products[0].items.length [1].items
      this.products = [...this.products]

      console.log('products', this.products);

    },
    methods: {

      async handleItemClick(e) {
        if (e.itemData.id != 3) {
          return;
        }
        this.ConChangedPop = true;
        this.SelChaAcc = e.itemData.account
      },

      async ConChange(Con) {

        if (Con == true) {
          let get_key = null;
          // 在組件掛載時生成密鑰
          get_key = await getKey();
          // const selectAcc = this.COMA.info.accounts.find(account => account.account == this.SelChaAcc)
          const selectAcc = this.COMA.accounts.find(account => account.account == this.SelChaAcc)

          const matchedAccountJSON = JSON.stringify(selectAcc);
          console.log('matchedAccountJSON', matchedAccountJSON)

          const { ciphertext: matchedAccountCiphertext, iv: matchedAccountIv } = await encryptData(matchedAccountJSON, get_key);
          sessionStorage.setItem("encryptedMatchedAccount", `${matchedAccountCiphertext}: ${matchedAccountIv}`); //選完帳號該帳號的資料
          this.ConChangedPop = false

          await this.$router.push('/home');
          location.reload();
          return true;
        }
        else if (Con == false) {
          this.ConChangedPop = false
          return;
        }
        else {
          this.ConChangedPop = false
          return;
        }
      },


      async generateItems() { //重選帳號
        console.log(this.COMA)
        //.info.
        return this.COMA.accounts.map(user => ({
          id: '3',
          name: user.companyid + " / " + user.account,
          account: user.account,
          icon: 'user'
        }));
      },

      async fetchsession() {
        let get_key = null;
        // 在組件掛載時生成密鑰
        get_key = await getKey();
        let encryptedData = sessionStorage.getItem('encryptedMatchedAccount');
        const [ciphertext, iv] = encryptedData.split(':');
        const decryptedText = await decryptData(ciphertext, get_key, iv);
        //.info.\
        console.log('this.company', decryptedText)

        this.company = JSON.parse(decryptedText).companyid;
      },
      changeLanguage() {
        this.$i18n.locale = this.selectedLanguage;
      },

      showForm() {
        if (this.form === '') {
          notify('Please enter the form');
        }
        else {
          const form = rptitem;
          const matchItem = form.find(itemData => itemData.name == this.form.toUpperCase());

          if (matchItem) {
            notify('載入 ' + this.form);

            this.$router.push({
              path: "/" + matchItem.path,
              query: { redirect: this.$route.path }
            });
          }
          else {
            sessionStorage.setItem('Query', this.form)
            this.$router.push({
              path: "/Faweb_Query",
            }).then(() => {
              window.location.reload(); //刷新頁面
            })
          }
        }
      },

      onLogoutClick() {
        auth.logOut();
        // console.log(JSON.parse( sessionStorage.getItem("User_acc")).ACCOUNT);
        this.$router.push({
          path: "/login-form",
        });

      }
    },
    setup() {
      const workno = ref("");
      workno.value = sessionStorage.getItem('workno');

      return {
        workno,
      };
    },
    components: {
      DxButton,
      DxToolbar,
      DxItem,
      DxMenu,
      DxPopup,
      // /*  DxContextMenu,*/DxPosition,
      // DxList
      // UserPanel,
      // DxTextBox
    },
    async mounted() {
      await this.fetchsession();
      this.products[0].items[0].name = `Welcome\n \n${this.company}\n to log in to CSWEB`
    }
  };
</script>

<style lang="scss">
  @import "../themes/generated/variables.base.scss";
  @import "../dx-styles.scss";

  .top-info {
    /* font-size: 1.5rem; */
    color: #214c7f;
    font-size: 20px;
  }

  .user-name,
  .dx-menu-item-text {

    font-size: 15px !important;
    // font-weight: 1000;
    /* font-family:"Roboto,RobotoFallback","Noto Kufi Arabic","Helvetica,Arial","sans-serif"; */

    color: #555 !important;
    font-weight: 500;
  }

  .dx-menu-base .dx-menu-item .dx-menu-item-content {
    width: fit-content;
    line-height: 0 !important;
  }

  .header-component {
    flex: 0 0 auto;
    z-index: 1;
    // box-shadow: 0 1px 3px rgba(255, 87, 36, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #ddd;

    .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
      color: $base-accent;

    }
  }

  .header-toolbar {
    background-color: #fff;
  }

  .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 40px;

    .screen-x-small & {
      padding: 0 20px;

    }
  }


  .dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
    width: $side-panel-min-width;
    /* text-align: center; */
    padding: 0;

  }

  .header-title .dx-item-content {
    padding: 0;
    margin: 0;

  }

  .dx-theme-generic {

    .dx-toolbar {

      padding: 10px 0;
    }

    .user-button>.dx-button-content {
      padding: 1px;
    }

    .user>.dx-button-content {
      padding: 1px;

    }
  }
</style>
<style scoped>
  /* .dx-menu-base{

} */

  .popup-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
  }

  .dx-button {
    background-color: $base-all-background;
  }

  .user-info {
    display: flex;
    align-items: center;

    .dx-toolbar-menu-section & {
      padding: 10px 6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .image-container {
      overflow: hidden;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      margin: 0 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

      .user-image {
        width: 100%;
        height: 100%;
        background: url("https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png") no-repeat #fff;
        background-size: cover;
      }
    }

    .user-name,
    .dx-menu-item-text {
      font-size: 14px;
      color: $base-text-color;
      margin: 0 9px;
    }

    .user-name {
      color: #fff;
    }
  }
</style>