<template>
  <!-- <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
     <div class="dx-card content">
      <div class="header">
        <div class="title">7987</div>
        <div class="description">546465</div>
      </div>
      <slot />
    </div>
  </dx-scroll-view> -->
<!-- <dx-scroll-view height="100%" width="100%" > -->
  <div class="side-nav-outer-tool" height="100%" width="100%" >
   <div >
    <slot />
   </div>
  </div>
   <!-- </dx-scroll-view> -->
</template>

<script>
// import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    // DxScrollView
  },
  props: {
    title: String,
    description: String
  }
};
</script>

<style lang="scss">
/* @import "../themes/generated/variables.base.scss"; */
.side-nav-outer-tool {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}
</style>
