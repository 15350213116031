import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp } from "vue";
import { createI18n } from 'vue-i18n';
import router from "./router";
import themes from "devextreme/ui/themes";
import App from "./App";
import appInfo from "./app-info";

import { generateKey } from "@/components/CryptoComponent.js";

themes.initialized(() => {
    window.BaseUrl = window.BaseUrl.axios_url;
    window.FileUrl = window.FileUrl.file_url;
    window.UserManualUrl = window.UserManualUrl.UserManual_url;
    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;

    const i18n = createI18n({
        locale: 'TW',
        messages: {
            JP: require('./language/JP.json'),
            TW: require('./language/TW.json'),
            EN: require('./language/EN.json'),
        },
    });

    async function initialize() {
        if (!sessionStorage.getItem('cryptoKey')) {
            await generateKey();
        }
    }

    app.use(initialize);
    app.use(i18n);
    app.mount('#app');
});
