<template>
  <div id="root">
    <!-- <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    /> -->
    <div :class="cssClasses">
      <component :is="$route.meta.layout" :title="title" :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge">
        <div class="content">
          <router-view></router-view>
        </div>
        <template #footer>
          <app-footer />
        </template>
      </component>
    </div>
  </div>
</template>

<script>
  import AppFooter from "./components/app-footer";
  import "./themes/generated/variables.base.scss";
  import 'bootstrap-v5/dist/css/bootstrap.min.css';
  import { sizes, subscribe, unsubscribe } from "./utils/media-query";

  import useInactivityLogout from './components/useInactivityLogout';
  import {
    getCurrentInstance,
    reactive,
    onMounted,
    onBeforeUnmount,
    computed, provide, ref
  } from "vue";


  function getScreenSizeInfo() {
    const screenSizes = sizes();

    return {
      isXSmall: screenSizes["screen-x-small"],
      isLarge: screenSizes["screen-large"],
      cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
    };
  }

  export default {
    components: {
      AppFooter
    },
    setup() {

      useInactivityLogout(); // 啟動 inactivity logout 機制
      const vm = getCurrentInstance();
      //  console.log('vm',vm);
      const title = vm.proxy.$appInfo.title;
      const screen = reactive({ getScreenSizeInfo: {} });
      screen.getScreenSizeInfo = getScreenSizeInfo();
      const clickedMenu = ref(null);
      //父祖件提供(共享)給子組件
      provide('clickedMenu', clickedMenu);
      function screenSizeChanged() {
        screen.getScreenSizeInfo = getScreenSizeInfo();
      }

      onMounted(() => {
        subscribe(screenSizeChanged);
      });

      onBeforeUnmount(() => {
        unsubscribe(screenSizeChanged);
      });

      const cssClasses = computed(() => {
        return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
      });

      return {
        title,
        screen,
        cssClasses,
        // clickedMenu
      };
    }
  };
</script>

<style lang="scss">
  @import "@/themes/generated/variables.base.scss";

  @import "@/assets/scss/yahome.scss"; 
  @import "@/assets/scss/yahomeUse.scss"; 
  /* @import "@/assets/scss/layout"; */
  /* @import "@/assets/scss/custom"; */
  html,
  body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  .content {
    // padding: 15px;
    // border-radius: 10px;
  }

  .app {
    @import "./themes/generated/variables.base.scss";
    /* @import "./themes/variables.base.scss";  */
    background-color: $base-app-background;
    /* background-color:white; */
    display: flex;
    height: 100%;
    width: 100%;

  }
</style>