
import axios from 'axios';
const config = {
  headers: {
    Authorization: 'bearer ' + sessionStorage.getItem("token"),
  }
};
// import notify from 'devextreme/ui/notify';
// import { useRouter } from 'vue-router';
import { encryptData, getKey, decryptData } from "@/components/CryptoComponent.js";
// import { DxKeyboardNavigation } from 'devextreme-vue/data-grid';
export default {

  async loggedIn() {
    // return JSON.parse(sessionStorage.getItem("LoginUserinfo")).token;
    //解密QQBB_Info
    let get_key = null;
    get_key = await getKey();
    const existingData = JSON.parse(sessionStorage.getItem('LoginUserinfo')) || [];
    const [DEciphertext, DEiv] = existingData.split(':');
    //console.log('QW', [DEciphertext, DEiv])
    const decryptedText = await decryptData(DEciphertext, get_key, DEiv);
    // const token = JSON.parse(decryptedText).info.token;
    const token = JSON.parse(decryptedText).token;

    //console.log('token', token);
    return token;
  },

  async News() {
    const apiUrl = `${window.BaseUrl}/api/Login/getNews`;
    await axios.post(apiUrl)
    try {
      const resp = await axios.post(apiUrl);
      if (resp.data.success) {
        if (resp.data.payload.length > 0) {
          return resp.data.payload;
        }
      }
      return []; // 返回空数组，确保函数总是返回一个数组
    } catch (error) {
      //console.error('Error fetching news:', error);
      return []; // 出现错误时，返回空数组
    }
  },

  async logIn(email, password) {
    let get_key = null;
    // 在組件掛載時生成密鑰
    get_key = await getKey();
    const bodyParameters = {
      email: email,
      password: password
    };
    const { ciphertext, iv } = await encryptData(JSON.stringify({ "password": password, "email": email }), get_key);
    sessionStorage.setItem("Info", JSON.stringify(`${ciphertext}:${iv}`));

    const apiUrl = `${window.BaseUrl}/api/Csweb2/Login`;
    // console.log(apiUrl,bodyParameters);
    try {
      const resp = await axios.post(apiUrl, bodyParameters, config);
      // Send request
      // console.log(resp);
      var apiRet = resp.data;
      if (apiRet.success) {
        const LoginUserinfo = apiRet.payload; //20230907 CCL* 加上儲存從後端取回的Token 
        const Info = {
          info: LoginUserinfo,
          password: password
        }


        const { ciphertext, iv } = await encryptData(JSON.stringify(Info), get_key);
        sessionStorage.setItem("LoginUserinfo", JSON.stringify(`${ciphertext}:${iv}`));//登入後，所有帳號的資料


        return {
          isOk: LoginUserinfo.msg_flag,
          acc_list: LoginUserinfo.accounts,
          message: apiRet.message
          //  tt:  this.token
        };
      }
      else {
        const { ciphertext, iv } = await encryptData(false, get_key);
        sessionStorage.setItem("login", JSON.stringify(`${ciphertext}:${iv}`));
        //console.log('login',sessionStorage.getItem('login'))
        // sessionStorage.setItem('login', false);
        return {
          isOk: apiRet.payload.msg_flag,
          message: apiRet.message
        };
      }
    }
    catch {
      const { ciphertext, iv } = await encryptData(false, get_key);
      sessionStorage.setItem("login", JSON.stringify(`${ciphertext}:${iv}`));
      return {
        isOk: 9,
        message: apiRet.message
      };
    }
  },

  ///api/Csweb2/checkPasswordUpdateTime
  async checkPassword(email, account) {
    try {
      const apiUrl = `${window.BaseUrl}/api/Csweb2/checkPasswordUpdateTime`;
      const resp = await axios.post(apiUrl, {
        "account": account, "email": email
      }, config);
      console.log(resp);
    } catch (error) {
      //console.error('Request failed with status:', error)
    }
  },
  async logOut() {
    //紀錄LOG
    let get_key = null;
    // 在組件掛載時生成密鑰
    get_key = await getKey();
    // let encryptedData = sessionStorage.getItem('User_acc');
    // const [ciphertext, iv] = encryptedData.split(':');
    // const decryptedText = await decryptData(ciphertext, get_key, iv);
    // const Account = JSON.parse(decryptedText).ACCOUNT;
    let encryptedData = sessionStorage.getItem('encryptedMatchedAccount');
    const [ciphertext, iv] = encryptedData.split(':');
    const decryptedText = await decryptData(ciphertext, get_key, iv);
    const Account = JSON.parse(decryptedText).account;

    const bodyParameters = {
      account: Account /*JSON.parse(sessionStorage.getItem("User_acc")).ACCOUNT*/,
      action: 'LOGOUT'
    };
    // console.log(JSON.parse( sessionStorage.getItem("User_acc")).ACCOUNT);
    const apiUrl = `${window.BaseUrl}/api/Csweb2/InsertUserLoginLog`;
    await axios.post(apiUrl, bodyParameters, config);
    sessionStorage.clear();
    localStorage.clear();
  },

  async getUser() {
    try {
      // Send request
      return {
        isOk: true,
        data: this._user,
      };
    }
    catch {
      return {
        isOk: false,
      };
    }
  },

  async resetPassword(email) {
    // const router = useRouter();
    const bodyParameters = {
      email: email
    };
    const apiUrl = `${window.BaseUrl}/api/Csweb2/ForgetPassword`;
    try {
      const resp = await axios.post(apiUrl, bodyParameters);
      // Send request
      var apiRet = resp.data;
      if (apiRet.success) {
        return {
          isOk: apiRet.success,
          message: apiRet.message
        };
      } else {
        return {
          isOk: apiRet.success,
          message: apiRet.message
        };
      }
    }
    catch {
      return {
        isOk: false,
        message: "Invalid Email Address, please enter an Email Address you registered with."
      };
    }
  },

  // async changePassword(email, recoveryCode) {
  //   try {
  //     // Send request
  //     // console.log(email, recoveryCode);
  //     return {
  //       isOk: true
  //     };
  //   }
  //   catch {
  //     return {
  //       isOk: false,
  //       message: "Failed to change password"
  //     }
  //   }
  // },

  // async createAccount(email, password) {
  //   try {
  //     // Send request
  //     console.log(email, password);

  //     return {
  //       isOk: true
  //     };
  //   }
  //   catch {
  //     return {
  //       isOk: false,
  //       message: "Failed to create account"
  //     };
  //   }
  // }
};
