import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

export default function useInactivityLogout() {
      const router = useRouter();
      const inactivityTime = ref(null);

      const logoutUser = () => {
            // 執行登出邏輯，例如清除 token 並跳轉至登錄頁面
            console.log('User has been logged out due to inactivity');
            router.push('/login-form');
      };

      const resetTimer = () => {
            if (inactivityTime.value) {
                  clearTimeout(inactivityTime.value);
            }
            inactivityTime.value = setTimeout(logoutUser, 3600000); // 60分鐘
      };

      const events = ['mousemove', 'keypress', 'click'];

      onMounted(() => {
            resetTimer();
            events.forEach(event => {
                  window.addEventListener(event, resetTimer);
            });
      });

      onUnmounted(() => {
            if (inactivityTime.value) {
                  clearTimeout(inactivityTime.value);
            }
            events.forEach(event => {
                  window.removeEventListener(event, resetTimer);
            });
      });
}