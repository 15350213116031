<template>
  <div class="content-block">
    <footer class="footer">
      Copyright © 2023-{{new Date().getFullYear()}} ASECL Inc.
      <!-- <br />All trademarks or registered trademarks are property of their
      respective owners. -->
    </footer>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        ITOwner: '',
        USER: ''
      }
    },
    watch: {

    },
    mounted() {

    },
    methods: {
    }
  }
</script>
<style lang="scss">
  @import "../themes/generated/variables.base.scss";

  .footer {
    display: block;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  }
</style>