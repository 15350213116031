import { createRouter, createWebHashHistory } from "vue-router";
import auth from "./auth";
import { getKey, decryptData } from "@/components/CryptoComponent.js";
import notify from 'devextreme/ui/notify';
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import CardLayout from "./layouts/csweb-card";
import router_data from "./data/RPTITEM.json";
import logLayout from "./layouts/log-card";
const r1 = router_data;
const routes = r1.map(route => {
  route.path = route.path.replace('.aspx', '').replace('.asp', '').replace('%2F', '/');
  return {
    path: `/${route.path}`,
    name: route.name,
    meta: loadMeta(route.auth, route.layout),
    component: loadView(route.name)
  };
});
// console.log(routes);
const router = new createRouter({
  base: '/CSWEB_Doris/',
  routes: routes,
  history: createWebHashHistory()
});

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

function loadMeta(auth, layout) {
  // console.log('auth',auth);
  const meta = {
    requiresAuth: true,
    layout: defaultLayout
  };

  if (layout == "defaultLayout") {
    meta.layout = defaultLayout;
  } else if (layout == "logLayout") {
    meta.layout = logLayout;
  }
  else if (layout == "simpleLayout") {
    meta.layout = simpleLayout;
  }
  else if (layout == "CardLayout") {
    meta.layout = CardLayout;
  }

  if (auth == "false") {
    meta.requiresAuth = false;
  }

  return meta;
}

router.beforeEach(async (to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const LoginUserinfo = JSON.parse(sessionStorage.getItem("LoginUserinfo"));
    // console.log(LoginUserinfo);
    if (LoginUserinfo == null) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    }

    const emailConfirm = LoginUserinfo.isNeedEmailConfirm;
    // console.log("emailConfirmTime");
    //  console.log(emailConfirm);
    if (emailConfirm) {
      notify('Please authenticate', "error");
      next({ name: "verify_mail" });
    }
    let get_key = null;
    // 在組件掛載時生成密鑰
    get_key = await getKey();
    let existingData = JSON.parse(sessionStorage.getItem('login')) || [];
    //console.log("encryptedLogin", existingData)
    sessionStorage.setItem('WhatISexistingData',existingData)
    let [ciphertext, iv] = existingData.split(':');
    let rrrr;//
    try {
      if (existingData) {
        rrrr = await decryptData(ciphertext, get_key, iv);//
        //console.log("[ciphertext, iv]", rrrr);//

      }
    } catch (error) {
      //console.error('Datastorage Error fetching', error);
    }
    const loginStatus = rrrr;
    //console.log('loginStatus',loginStatus)
    // console.log('auth', auth.loggedIn());
    // const token = auth.loggedIn();
    if (auth.loggedIn()) {
      if (loginStatus === 'expired') {
        // Notify user about the expired login status
        notify('Password Expired, please change your password', 'error');
        // Redirect to LoginMemberProfile if not already there
        if (to.name !== 'LoginMemberProfile') {
          next({ name: 'LoginMemberProfile' });
        } else {
          // Allow access if already on LoginMemberProfile
          next();
        }
      } else if (loginStatus) {
        // User is logged in and allowed access
        next();
      } else {
        // User is not logged in, redirect to LoginForm
        next({ name: 'LoginForm' });
      }
    } else {
      // Route does not require authentication, allow access
      next();
    }
  } else {
    next();
  }
});
export default router;
